.rentalSession-additionalTime {
    padding: 2rem;
    gap: 2rem;
}
.rentalSession-additionalTime input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.additionalTime-prices {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.9rem;
    width: 100%;
}
.additionalTime-prices > div {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.rentalSession-additionalTime label {
    font-size: 1rem;
}

.additionalTime-footer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}
.additionalTime-totalPrice-container {
    display: flex;
    justify-content: space-between;
}

.additionalTime-actions {
    margin-top: 0px;
}

.rentalSession-additionalTime .add:disabled {
    opacity: 0.5;
}

.rentalSession-additionalTime .cancel:disabled {
    opacity: 0.5;
}

.additionalPrice-timeInput-container .sessionSetup-timeInputs {
    width: 50%;
}

.additionalPrice-timeInput-container {
    align-items: center;
}

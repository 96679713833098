.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 32px;
}

.login-title {
    font-size: 60px;
    margin-bottom: 20px;
}

.login-container form {
    width: 20em;
    background-color: var(--primary-color);
    color: white;
    padding: 50px;
    height: fit-content;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-container form input {
    height: 4rem;
    padding: 2px;
    border-radius: 10px;
    margin: 30px 0;
    width: 80%;
    font-size: 32px;
    text-align: center;
}
.login-container form button {
    font-size: 2rem;
    height: 4rem;
    width: 50%;
    margin-top: 20px;
    cursor: pointer;
}

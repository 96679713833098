.dashboard-pieChart-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 0;
    flex-basis: 40rem;
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 1rem;
    width: 20rem;
}

.dashboard-pieChart-wrapper .MuiPieArc-root {
    stroke: #000000;
    stroke-width: 0.6;
    stroke-linejoin: round;
}

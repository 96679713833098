.App {
    text-align: center;
    height: 100%;
    margin: 0;
    padding: 0;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background: rgb(179, 178, 178);
    border-radius: 4px;
}
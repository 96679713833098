.navbar-wrapper {
    font-size: 0.8rem;
    width: 100%;
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    color: white;
    cursor: default;
}

.navbar-name {
    font-size: 1.2rem;
}

.navbar-wrapper a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.navbar-left {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
    vertical-align: sub;
}

.navbar-right {
    display: flex;
    gap: 3rem;
    align-items: center;
    vertical-align: sub;
}

.MuiAppBar-root {
    flex: none !important;
    background-color: var(--primary-color) !important;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2) !important;
}

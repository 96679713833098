.sessionEdit-header {
    letter-spacing: 1.3px;
    margin-top: 0;
    margin-bottom: 2rem;
}

.sessionEdit-body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 1.2rem;
    align-items: center;
}

.sessionEdit-note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sessionEdit-noteIput {
    width: 100%;
    border-color: var(--primary-white);
}

.sessionEdit-noteIput * {
    color: var(--primary-white) !important;
    border-color: var(--primary-white) !important;
}

.paymentTypeInputs {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 1rem;
}

.paymentMethodInput {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 6em;
}

.paymentTypeLabel {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    justify-content: flex-start;
    line-height: 1rem;
    margin-bottom: 0.3rem;
    font-weight: 500;
}

.paymentTypeIcon {
    font-size: 1rem !important;
    display: flex;
}

.payment-input {
    width: 100%;
    height: 2em;
    font-size: 1em;
    background-color: transparent;
    border-radius: 7px;
    font-weight: 100;
    color: white;
    cursor: text;
    text-align: center;
    border: 1px solid rgb(178, 169, 169);
}

.payment-input::-webkit-outer-spin-button,
.payment-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cashRegistry-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.cashRegistry-body-item {
    padding: 0.5rem;
}

.cashRegistry-popover-wrapper {
    display: flex;
}

.cashRegistry-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15rem;
    padding: 0.5rem 0.5rem 1rem;
}

.MuiInput-root {
    font-size: 1.3rem !important;
}
.MuiInputLabel-root {
    font-size: 1.2rem !important;
}

@media (max-width: 500px) {
    .cashRegistry-wrapper {
        justify-content: flex-start;
    }
}


.dashboard-wrapper-background {
    background-color: var(--secondary-blue);
    overflow-y: scroll;
    height: 100vh;
    color: var(--primary-white);
    padding: 2rem 2rem 2rem 2rem;
}

.dashboard-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 2rem;
}


.dashboard-bottom-wrapper {
    display: flex;
}

.dashboard-chart-title {
    margin: 1rem 0 0.5rem 0;
}

.dashboard-dataTimePickers-item * {
    color: var(--primary-white) !important;
    border-color: white !important;
}

.dashboard-dataTimePickers-wrapper {
    position: absolute;
    right: 50%;
    transform: translate(50%, 0);
    width: 27rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    z-index: 10;
    justify-content: center;
}

.notSupported-dashboard {
    display: flex;
    position: relative;
    width: 90%;
    height: fit-content;
    color: var(--primary-white);
    border-radius: 1rem;
    padding: 1.5rem;
    font-size: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
}
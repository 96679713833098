.protected-route-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    -ms-flex-direction: column;
}

.protected-route-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    min-height: 0;
}

.protected-route-main > .MuiBox-root > main.MuiBox-root {
    overflow-x: scroll
}

.sessionLink-pupUp {
    width: 25rem;
    padding: 2rem;
}

.sessionLink-prices {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.sessionLink-priceSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    width: 100%;
}

.sessionLink-priceSection span {
    color: var(--primary-white);
}

.sessionLink-timeInputs {
    display: flex;
    width: 100%;
}

.LinkPopUp-priceIndicators {
    position: relative;
    font-size: 1rem;
    color: var(--secondary-white);
    border-bottom: 1px solid var(--primary-grey);
    width: 12rem;
    top: -0.4rem;
    text-align: right;
}


.sessionLink-inputClass {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 12rem;
    height: 2.2rem;
    top: -0.4rem;
}

.sessionLink-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
}
.rentalCard-sessionEnd {
    gap: 1.5em;
    width: 20rem;
}

.endPopUp-priceIndicators {
    font-size: 1rem;
    color: var(--secondary-white);
}

.rentalCard-sessionEnd input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

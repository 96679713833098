.history-detail-wrapper {
    margin-left: 5.4%;
    display: flex;
    justify-content: flex-start;
    gap: 5rem;
}

.event-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.history-detail-item {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.history-detail {
    display: flex;
    flex-direction: column;
}

.history-detail-type-value {
    font-size: 0.75rem;
}

.history-detail > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.history-detail-wrapper h5 {
    padding: 0;
    margin: 0;
}

.datePicker input {
    height: 0.5rem;
    font-size: 0.8rem;
}

.topTolbarCustomActions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.history-detail-table-item {
    padding-right: 10px;
}

.history-detail-table-username-item {
    font-weight: bold;
}

.history-paymentsSum-container {
    display: flex;
    gap: 1rem;
}
.productSales-wrapper {
    position: absolute;
    right: 0;
    margin: 25px;
    box-shadow: 0 5px 60px 0 var(--primary-color);
    border-radius: 10px;
    z-index: 1000;
    width: 20rem;
}

.productSales-wrapper input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.productSales-wrapper .selectedProducts-buttons {
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productSales-wrapper .selectedProducts-buttons button {
    font-size: 0.8rem;
    border-radius: 5px;
    letter-spacing: 1.1px;
    margin: 20px;
    cursor: pointer;
}

.productSales-wrapper .selectedProducts-buttons .sale-container {
    display: flex;
    margin: 20px 0;
    gap: 1rem;
}

.productSales-wrapper .selectedProducts-buttons .sale-container .sale-button {
    margin: 0;
}

.selectedProducts-buttons .done-button {
    background-color: var(--primary-green);
    border-color: color-mix(in srgb, var(--primary-green) 40%, white);
    color: color-mix(in srgb, var(--primary-green) 40%, white);
    font-size: 0.8rem;
}

.selectedProducts-buttons .reset-button {
    background-color: var(--primary-yellow);
    border-color: color-mix(in srgb, var(--primary-yellow) 40%, white);
    color: color-mix(in srgb, var(--primary-yellow) 40%, orange);
    font-size: 0.1rem;
}

.productSales-wrapper .payment-input {
    width: 100%;
    height: 1.5rem;
    font-size: 0.8rem;
    text-align: center;
    background-color: transparent;
    border-radius: 7px;
    cursor: text;
}

.recipe-backdrop {
    z-index: 9999;
}

.recipe-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10000;
    transform: translate(-50%, -50%);
    display: flex;
    color: var(--primary-white);
    flex-direction: column;
    padding: 20px;
    background-color: var(--primary-color);
    border-radius: 10px;
    box-shadow: 0 0 0 0.5px var(--secondary-color);
}

.recipe-wrapper .recipe-totalamount {
    padding-right: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 5px;
}

.recipe-wrapper button {
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 5px;
    letter-spacing: 1.1px;
    cursor: pointer;
    padding: 4px 10px;
}

.closeButton {
    position: absolute !important;
    right: 3px;
    top: 4px;
    z-index: 1000;
}

.productTable-toggle {
    border-radius: 100%;
    z-index: 1000;
    background-color: var(--primary-yellow);
    position: absolute;
    right: 0;
    margin: 50px;
    outline: 5px solid var(--primary-yellow);
    outline-offset: -1px;
}

.productTable-toggle:hover {
    box-shadow: 1px 1px 10px black;
}

.total-price-input {
    width: 3.5rem;
    font-size: 0.8rem;
    text-align: right;
    font-weight: 600;
    background-color: transparent;
    border: none;
    color: white;
    padding-bottom: 1rem;
}

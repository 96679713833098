.calculatorWrapper {
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: -50%;
    top: 50%;
    transform: translate(-50%);
}

.calculatorPopover .MuiPopover-paper {
    background-color: var(--secondary-blue) !important;
}

.changeCalculatorButton {
    padding: 0 !important;
    color: white !important;
}

.calculatorPopup {
    background-color: var(--secondary-blue);
    color: var(--primary-white);
    font-size: 0.75rem;
    padding: 0.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
}

.calculatorItem {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.priceToPayRow {
    padding-bottom: 0.5rem;
}

.priceToPayNumber {
    direction: rtl;
}

.changeNumber {
    display: flex;
    flex-direction: row-reverse;
    width: 4rem;
}

.amountReceivedInput {
    width: 4rem;
    background-color: transparent;
    color: white;
    border: none;
    text-align: right;
    border-bottom: 1px solid var(--primary-grey);
    padding-right: 0.62rem;
}

.amountReceivedInput:focus {
    border: none;
    border-bottom: 1px solid var(--primary-white);
    outline: none;
}
.amountReceivedInput:hover {
    border-color: var(--primary-white);
}

.amountReceivedLabel {
    position: relative;
}

.amountReceivedLabel::before {
    content: attr(currency-sign);
    position: absolute;
    right: 0;
    bottom: 0.035rem;
}

.amountReceivedInput::-webkit-inner-spin-button,
.amountReceivedInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.amountReceivedInput::placeholder {
    color: white;
    opacity: 0.5;
}

.changeRow {
    color: var(--primary-red);
    border-top: 1px solid var(--border-color);
    padding-top: 0.3rem;
}

.closeIconRow {
    display: flex;
    justify-content: flex-end;
}

.closeIcon {
    font-size: 1rem !important;
}

.closeIcon:hover {
    cursor: pointer;
}

.excelDownload-wrapper {
    display: flex;
}

.export-popover-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.dailyExport-popover .MuiPopover-paper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1.5rem;
}

.dailyExport-DatePicker {
    width: 15rem;
}

.dailyExport-DatePicker > .MuiMenu-paper {
    width: 15rem;
}

.dailyExport-dataPicker-item {
    text-align: center;
}

.excel-download-png {
    width: 1.8rem;
}

.daily-export-button {
    color: green;
    border-width: 2px;
    margin: auto;
    width: 40%;
}
.dailyExport-menuItem {
    justify-content: center !important;
}

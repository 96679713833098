.sessionEdit-header {
    letter-spacing: 1.3px;
    margin-top: 0;
    margin-bottom: 2rem;
}

.sessionEdit-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.2rem;
}

.sessionEdit-item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 2rem;
}

.sessionEdit-inputClass {
    display: flex;
    justify-content: flex-end;
    width: 11rem;
}

.sessionEdit-note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sessionEdit-noteIput {
    width: 100%;
    border-color: var(--primary-white);
}

.sessionEdit-noteIput * {
    color: var(--primary-white) !important;
    border-color: var(--primary-white) !important;
}

.SessionEdit-timeInput {
    font-size: 1rem;
    width: 11rem;
    background-color: transparent;
    border: none;
    color: var(--primary-white);
    border-bottom: 1px solid var(--primary-grey);
    cursor: text;
    text-align: center;
}

.SessionEdit-timeInput::-webkit-calendar-picker-indicator {
    filter: invert(100%);
    cursor: pointer;
    font-size: 1.2rem;
}

.SessionEdit-timeInput:focus {
    outline: none;
    border-color: var(--primary-white);
}
.SessionEdit-timeInput:hover {
    border-color: var(--primary-white);
}

.footer-wrapper {
    width: 100%;
    background-color: var(--footer-color);
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    color: white;
    box-shadow: 4px 0 7px 0px rgba(255, 255, 255, 0.3);
    font-size: 0.5rem;
}

.footer-wrapper a {
    text-decoration: none;
    color: white;
    text-decoration: underline;
}

.footer-left {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
    vertical-align: sub;
    font-size: 12px;
}

.footer-right {
    display: flex;
    gap: 3rem;
    align-items: center;
    vertical-align: sub;
    font-size: 12px;
}

.footer-developed-by {
    gap: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.expiredSessions-container {
    z-index: 800;
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translate(0, -50%);
}

.expiredSessions-container.red {
    border-color: red !important;
}

.expiredSessions-stack {
    max-height: 50vh;
    padding: 0 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: scroll;
}

.expiredSessions-container.white {
    border-color: white !important;
}

.expiredSessions-toggleVolume {
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    transform: translate(-50%, 50%);
}

.expiredSession-info {
    font-size: 1.2rem;
}

.expiredSessions-container > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.expiredSessions-container > div:first-child {
    justify-content: center;
}

.expiredSessions-container > div:first-child > svg {
    color: red;
    font-size: xx-large;
}

.expiredSessions-container > div:last-child > span {
    font-size: large;
    color: red;
    font-weight: 600;
}

.expiredSessions-item {
    cursor: pointer;
    min-height: fit-content;
}

.expiredSessions-item > div:first-child {
    position: relative;
    width: 0 !important;
    height: 0;
}

.expiredSessions-item > div > svg {
    color: white;
}

.expiredSession-drawer-toggle {
    position: absolute !important; ;
    z-index: 900;
    background-color: var(--primary-red) !important;
    border-radius: 0.2rem  0 0 0.2rem !important;
    right: 0;
    top: 22%;
}

.drawer-toggle-icon {
    color: white;
}

@media (max-width: 500px) {
    .expiredSessions-stack {
        max-height: 100%;
        margin-top: 3rem;
    }

    .expiredSessions-container > div:first-child > svg {
        font-size: 1rem;
    }

    .expiredSessions-toggleVolume {
        font-size: 2rem !important;
        z-index: 10000;
    }

    .expiredSessions-container > div:last-child > span {
        font-size: 1rem;
        color: red;
        font-weight: 400;
    }

    .expiredSession-info {
        font-size: 1rem;
        text-align: center;
    }
}
.SessionMenu-popper {
    z-index: 10000;
}

.SessionMenu-paper {
    background-color: var(--primary-color) !important;
    color: white !important;
}

.SessionMenu-item {
    font-size: 0.8rem !important;
    display: flex !important;
    justify-content: space-between !important;
    gap: 1rem;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--secondary-blue);
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
}

@media (max-width: 500px) {
    html {
        font-size: 14px;
    }
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:root {
    --primary-color: #24305e;
    --secondary-blue: #374785;
    --secondary-color: #a8d0e6;
    --primary-white: white;
    --primary-red: #f76c6c;
    --primary-yellow: #f8e9a1;
    --primary-green: #7ac434d1;
    --primary-grey: rgb(178, 169, 169);
    --secondary-white: rgb(227, 224, 224);
    --background-color: var(--secondary-color);
    --navbar-color: var(--primary-color);
    --footer-color: var(--primary-color);
    --font-color: var(--primary-white);
    --border-color: var(--primary-grey);
}

#root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.bulkTable-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10000;
    transform: translate(-50%, -50%);
    margin: 10px;
    color: var(--primary-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: 80%;
}

.rentalSessionCards-wrapper {
    justify-content: left;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
}

.rentalCard-wrapper {
    width: 20rem;
    height: 11rem;
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    box-shadow: 1px 1px 15px rgb(150, 148, 148);
    color: var(--primary-color);
    font-size: 1rem;
}

.rentalCard-wrapper:hover {
    outline: 1px solid color-mix(in srgb, var(--primary-color) 100%, white 50%);
}

.Mui-TableBodyCell-DetailPanel {
    width: 100%;
}

td.Mui-TableBodyCell-DetailPanel {
    width: 100%;
}

td.Mui-TableBodyCell-DetailPanel > * {
    width: 100%;
}

.rentalCard-name {
    font-size: 1rem;
}

.rentalCard-left {
    display: flex;
    flex: 50%;
    flex-direction: column;
}

.rentalCard-liveSession-controlPanel {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.rentalCard-liveSession-controlPanel > * {
    flex: 1;
}

.session-time-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.session-time-actions-items {
    position: relative;
    top: -0.85rem;
}

.session-time-actions-item {
    padding: 0 !important;
    display: flex;
    height: 1.5rem;
}

.session-time-actions-item.pause:disabled > * {
    opacity: 0.5;
}

.session-time-actions-item > svg {
    font-size: 1.5rem !important;
    display: flex;
    color: var(--primary-color);
}

.rentalCard-liveSession-finish {
    display: flex;
    justify-content: center;
    align-items: center;
}

input.time-input:disabled {
    opacity: 50%;
}

.rentalCard-openPopup {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.rentalCard-openPopup:hover {
    border-radius: 10px;
}

.rentalCard-openPopup .rentalCard-openPupupPlus {
    transition: font-size ease-in 0.15s;
}

.rentalCard-openPopup:hover .rentalCard-openPupupPlus {
    font-size: 4rem;
    transition: font-size ease-in 0.15s;
}

.rentalSession-sessionProductSale-background {
    display: flex;
    position: fixed;
    border-radius: 10px;
    max-width: 55rem;
    width: 90%;
    max-height: 70%;
    left: 50%;
    top: 50%;
    background-color: var(--primary-color);
    padding: 2rem;
    transform: translate(-50%, -50%);
    z-index: 10000;
    overflow: hidden;
}

.rentalSession-sessionProductSale {
    width: 100%;
}

.rentalSession-sessionProductSale .productSales-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30rem !important;
    gap: 1rem;
    position: initial;
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    box-shadow: none;
}

.rentalSession-sessionProductSale .productSales-wrapper > div:nth-child(2) {
    flex: 1;
    height: 25rem;
}

.rentalSession-sessionProductSale .productSales-wrapper > div:nth-child(2) > div:last-child {
    height: 25rem !important;
}

.rentalSession-sessionProductSale .productSales-wrapper > div:last-child {
    flex: 1;
    height: 25rem;
}

.rentalSession-sessionProductSale .productSales-wrapper > div:last-child {
    display: flex;
    flex-direction: column;
}

.rentalSession-sessionProductSale .productSales-wrapper > div:last-child > div:nth-child(2) > div {
    height: 100%;
}

.rentalSession-sessionProductSale .productSales-wrapper > div:last-child > div:first-child {
    display: flex;
    height: 3rem !important;
    justify-content: center;
    align-items: center;
}

.rentalSession-sessionProductSale .productSales-wrapper > div:last-child > div:last-child {
    position: absolute;
    top: 100%;
    left: 100%;
    box-shadow: none;
    transform: translate(-100%, -100%);
    background-color: var(--primary-color);
    border-radius: 4px;
}

.rentalSession-sessionProductSale .productSales-wrapper > div:last-child > div:last-child * {
    box-shadow: none;
}

.rentalSession-sessionProductSale
    .productSales-wrapper
    > div:last-child
    > div:last-child
    > div:first-child
    input {
    margin: 0 !important;
}

.rentalSession-sessionProductSale
    .productSales-wrapper
    > div:last-child
    > div:last-child
    .selectedProducts-buttons {
    display: flex;
    justify-content: space-evenly;
}

.rentalCard-menu {
    display: flex;
    flex-direction: column;
    flex: 50%;
    background-color: var(--secondary-blue);
    border-radius: 10px;
}

.rentalCard-menu-action {
    display: flex;
    height: 1.5rem;
    justify-content: center;
    padding-top: 0.5rem;
    background-color: var(--primary-green) !important;
    border-radius: 0 0 9px 9px !important;
}

.rentalCard-menu-action svg {
    font-size: 1rem;
}

.rentalCard-menu-action:hover {
    border-radius: 0;
    background-color: color-mix(in srgb, var(--primary-green) 80%, transparent) !important;
}

.rentalCard-menu-action > * {
    color: white;
}

.rentalCard-menu-container {
    flex: 90%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    overflow-y: scroll;
}

.rentalCard-menu-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.8rem;
    color: white;
    border-bottom: 1px solid var(--secondary-color);
}

@media (max-width: 500px) {
    .rentalSessionCards-wrapper {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 780px) {
    .rentalSession-sessionProductSale .productSales-wrapper {
        flex-direction: column;
    }
}

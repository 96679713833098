.dashboard-BarChart-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    width: 10rem;
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 1rem;
}

.dashboard-BarChart-wrapper .MuiChartsAxis-line,
.MuiChartsAxis-tick {
    stroke: rgba(255, 255, 255, 0.87) !important;
    shape-rendering: crispEdges;
    stroke-width: 1;
}

.dashboard-BarChart-wrapper .MuiChartsAxis-tickLabel {
    fill: white !important;
}


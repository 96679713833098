.total-balance {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.total-balance-item {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    border-bottom: 1px solid gray;
}

.total-balance-item > input {
    width: 5rem;
    font-size: 1em;
    text-align: center;
    border: none;
    background-color: transparent;
}

.refresh-balance-button {
    padding: 1.5px !important;
}

.alert-backdrop {
    z-index: 9999;
}

.alert-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10000;
    transform: translate(-50%, -50%);
    margin: 10px;
    width: 300px;
    height: 200px;
    color: var(--primary-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-color: var(--primary-color);
    border-radius: 10px;
    box-shadow: 0px 0px 0px 0.5px var(--secondary-color);
}

.alert-wrapper .alert-close {
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px 10px 10px;
    cursor: pointer;
}

.alert-message {
    word-break: normal;
    font-size: 1.5rem;
}

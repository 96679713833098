.dashboard-latencyChart-wrapper {
    margin-top: 2rem;

    min-height: 35rem;
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 1rem;
}

.dashboard-latencyCharts-container {
    display: flex;
    width: 100%;
    padding-bottom: 2rem;
}

.dashboard-latencyChart-max {
    margin-top: 2rem;
    flex: 1;
    height: 28rem;
    display: flex;
    flex-direction: row;

}

.dashboard-latencyChart-wrapper .MuiChartsAxis-line,
.MuiChartsAxis-tick {
    stroke: rgba(255, 255, 255, 0.87) !important;
    shape-rendering: crispEdges;
    stroke-width: 1;
}

.dashboard-latencyChart-wrapper .MuiChartsAxis-tickLabel {
    fill: white !important;
}

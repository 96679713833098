.popUp-wrapper {
    color: var(--primary-white);
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-blue);
    box-shadow: 0px 0px 15px var(--primary-color);
    border-radius: 10px;
    width: 25rem;
}

.MuiFormGroup-root {
    width: 100%;
    display: flex;
    justify-content: center;
}

.react-select__single-value {
    color: white !important;
}

.react-select__input-container {
    color: white !important;
}

.react-select__placeholder {
    color: white !important;
}

.react-select__indicator-separator {
    display: none;
}

.sessionPopUp-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 2em;
}

.popUp-button {
    height: 1.6rem;
    background-color: transparent;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    width: 5rem;
    cursor: pointer;
    border: none;
    border-bottom: 1px solid;
    color: var(--secondary-white);
    border-radius: 3px;
}

.popUp-button:disabled {
    opacity: 0.5;
}

.start {
    border-color: var(--primary-green);
}

.cancel {
    border-color: var(--primary-red);
}

.cancel:hover {
    color: var(--primary-red);
    border: 1px solid;
}

.start:hover {
    color: var(--primary-green);
    border: 1px solid;
}

.priceInputs {
    height: 1.1rem;
    display: flex;
    width: 50%;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid white;
    color: white;
    border-radius: 2px;
    text-align: right !important;
    font-size: 0.9rem;
}

.inputWindows:hover {
    transition: all 0.2s;
    border-color: white;
}

.inputWindows {
    width: 100%;
    height: 2.2rem;
    top: -0.4rem;
}

.react-select__value-container {
    cursor: text;
}

.react-select__indicator svg {
    cursor: pointer;
}

.circularProgress {
    width: 1rem !important;
    height: 1rem !important;
    color: inherit !important;
}

.totalPriceSection {
    width: 62%;
    border-bottom: 1px solid rgb(142, 137, 137);
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    gap: 0.5rem;
    color: var(--secondary-color);
}

.sessionSetup-rentalType {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sessionSetup-timeDuration {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sessionSetup-price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sessionSetup-inputClass {
    display: flex;
    justify-content: flex-end;
    width: 11rem;
}

.sessionSetup-priceInput {
    width: 100%;
}

.sessionSetup-timeInputs {
    display: flex;
    width: 100%;
}

.sessionSetup-item {
    flex: 1 1 3.5rem;
    align-items: center;
}

.sessionSetup-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sessionSetup-footer {
    flex: 0 0 7rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.liveSession-price {
    width: 4rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 4px;
    color: var(--primary-color);
}

.liveSession-price-label {
    font-size: 1rem;
    margin-right: 4px;
}

.timerWrapper {
    display: flex;
    flex-direction: column;
}

.mimdinare {
    font-size: 1.8rem !important;
}

.mimdinare.live {
    color: green;
    display: flex;
    width: fit-content;
    justify-content: flex-start;
    align-items: center;
}
.fixed {
    color: var(--primary-color);
    display: flex;
    width: fit-content;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.8rem !important;
}

.fixed.live {
    color: var(--primary-yellow);
}
.fixed.expired {
    color: red;
}

.fixed.paused {
    color: gray;
}
